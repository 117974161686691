import React from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import BodyStep from "../common/BodyStep";
import IndustrySection from "../../../common/components/OrganizationForm/sections/IndustrySection";
import I18n from "../../../utils/i18n";

const IndustryStep = ({ whitelabel }) => {
    const { errors, values } = useFormikContext();

    return (
        <BodyStep
            title={I18n.t("activerecord.models.industry")}
            text={I18n.t("wizard.steps.industry.text", { whitelabel: whitelabel })}
            title_tip={I18n.t("wizard.steps.industry.title_tip")}
        >
            <IndustrySection
                errors={_get(errors, "organization.industry_relations_attributes") || []}
                values={values.organization.industry_relations_attributes}
                menuPlacement="bottom"
                tip={I18n.t("wizard.steps.industry.industry_tip")}
            />
        </BodyStep>
    );
};

IndustryStep.propTypes = {
    whitelabel: PropTypes.string.isRequired,
};

export default IndustryStep;
