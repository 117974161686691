import React, { useCallback, useState } from "react";
import { Card, Tabs, Tab } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import I18n from "../../../../utils/i18n";
import { getNegotiations } from "../../../../api/informationApi";
import ActiveTable from "./ActiveTable";
import NewProjectButton from "../NewProjectButton";
import DraftTable from "./DraftTable";

const Negotiations = () => {
    const [activeId, setActiveId] = useState("active");
    const [negotiations, setNegotiations] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [page, setPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(0);

    const onSelect = useCallback(
        (id) => {
            setPage(1);
            setActiveId(id);
        },
        [setPage, setActiveId],
    );

    const goToPage = ({ selected }) => setPage(selected + 1);

    const fetchData = useCallback(
        ({ sortBy }) => {
            const sort = sortBy[0];
            setIsFetching(true);

            const params = {
                q: {
                    page: page,
                    order_by: sort.id,
                    ordering: sort.desc ? "desc" : "asc",
                },
            };

            if (activeId === "draft") {
                params.q.status = "Draft";
            }

            getNegotiations(params)
                .then(({ data: { records, pages_count } }) => {
                    setNegotiations(records);
                    setPagesCount(activeId === "draft" ? 1 : pages_count);
                })
                .catch(() => setNegotiations([]))
                .finally(() => setIsFetching(false));
        },
        [page, activeId],
    );

    return (
        <>
            <h3>{I18n.t("home.negotiations.header")}</h3>
            <Card className="mb-2">
                <Card.Header className="bg-white pt-3 pb-0 border-0">
                    <div className="d-flex justify-content-end">
                        <NewProjectButton />
                    </div>
                </Card.Header>
                <Card.Body className="pt-0">
                    <Tabs activeKey={activeId} onSelect={onSelect}>
                        <Tab eventKey="active" title={I18n.t("home.negotiations.tabs.active")}>
                            {activeId === "active" && (
                                <div className="mt-3">
                                    <ActiveTable fetchData={fetchData} isFetching={isFetching} data={negotiations} />
                                    {pagesCount > 1 && (
                                        <div className="pagination justify-content-center mb-3">
                                            <ReactPaginate
                                                previousLabel="«"
                                                nextLabel="»"
                                                breakLabel="..."
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                pageCount={pagesCount}
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={3}
                                                onPageChange={goToPage}
                                                containerClassName="pagination pagination-sm mb-0"
                                                pageClassName="page-item"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextLinkClassName="page-link"
                                                nextClassName="page-item"
                                                pageLinkClassName="page-link"
                                                activeClassName="active"
                                                forcePage={page - 1}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </Tab>
                        <Tab eventKey="draft" title={I18n.t("home.negotiations.tabs.draft")}>
                            {activeId === "draft" && (
                                <div className="mt-3">
                                    <DraftTable isFetching={isFetching} fetchData={fetchData} data={negotiations} />
                                </div>
                            )}
                        </Tab>
                    </Tabs>
                </Card.Body>
            </Card>
        </>
    );
};

export default Negotiations;
