import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col, Container, Button, Spinner } from "react-bootstrap";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import I18n from "../../utils/i18n";
import SetupStep from "./components/SetupStep";
import UploadStep from "./components/UploadStep";
import ReviewStep from "./components/ReviewStep";
import { getSubscriptions } from "../../api/paymentsApi";
import { createPartnerships } from "../../api/tieredDiversityApi";
import { sanitizeLink } from "../../utils/sanitizeText";
import { StepperHeader } from "../../common/components/Stepper";

const STEPS = [
    { title: I18n.t("tiered_diversity.suppliers.new.steps.setup.title") },
    { title: I18n.t("tiered_diversity.suppliers.new.steps.upload.title") },
    { title: I18n.t("tiered_diversity.suppliers.new.steps.review.title") },
];

const NewTieredDiversitySupplierPage = () => {
    const history = useHistory();
    const currentWhitelabel = useSelector((state) => state.currentWhitelabel.resource);
    const [currentStep, setCurrentStep] = useState(0);
    const [periodicity, setPeriodicity] = useState("quarterly");
    const [revenue, setRevenue] = useState(0);
    const [currency, setCurrency] = useState(currentWhitelabel.currency);
    const [period, setPeriod] = useState({ from: 0, to: 0 });
    const [format, setFormat] = useState("amount");
    const [uploadResults, setUploadResults] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [requestParams, setRequestParams] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});

    const whitelabel = useSelector((state) => state.currentWhitelabel.resource);
    const currentOrganizationUid = useSelector((state) => state.currentActor.resource.organization.uid);

    const onSubmit = () => {
        setIsSubmitting(true);

        createPartnerships({
            type: format,
            period: period,
            suppliers: requestParams,
            customer: { revenue_currency: currency, revenue_amount: revenue },
        }).catch((error) => {
            if (error.response.status === 402) {
                history.push("/tiered_diversity/pricing");
            } else if (error.response.status === 403) {
                history.push("/");
            } else {
                setIsSubmitting(false);
            }
        });
    };

    useEffect(
        () =>
            getSubscriptions(currentOrganizationUid).then((response) =>
                setSubscriptions(_filter(response.data, (subscription) => subscription.active)),
            ),
        [],
    );

    return (
        <Container fluid className="d-flex flex-column content-with-navbar overflow-auto">
            <Row className="vh-100">
                <Col sm={2}>
                    <div className="d-flex align-items-start flex-column h-100">
                        <div className="mt-3 pt-3">
                            <h2>{I18n.t("tiered_diversity.suppliers.new.title")}</h2>
                        </div>
                        <div className="fill-vertical-height">
                            <StepperHeader
                                currentStep={currentStep}
                                steps={STEPS}
                                styles="pt-5 pb-5"
                                direction="vertical"
                            />
                        </div>
                        <div
                            className="align-self-end mb-3 mt-5"
                            dangerouslySetInnerHTML={sanitizeLink(
                                I18n.t("wizard.help_link", {
                                    whitelabel_mail_to: whitelabel.mail_to,
                                }),
                            )}
                        />
                    </div>
                </Col>
                <Col sm={10} className="bg-white overflow-auto h-100">
                    <div className="d-flex align-items-start justify-content-between flex-column h-100">
                        {currentStep === 0 && (
                            <SetupStep
                                periodicity={periodicity}
                                setPeriodicity={setPeriodicity}
                                period={period}
                                setPeriod={setPeriod}
                            />
                        )}
                        {currentStep === 1 && (
                            <UploadStep
                                format={format}
                                setFormat={setFormat}
                                period={period}
                                setCurrentStep={setCurrentStep}
                                setUploadResults={setUploadResults}
                                currency={currency}
                                setCurrency={setCurrency}
                                revenue={revenue}
                                setRevenue={setRevenue}
                                validationErrors={validationErrors}
                                setValidationErrors={setValidationErrors}
                            />
                        )}
                        {currentStep === 2 && (
                            <ReviewStep
                                period={period}
                                uploadResults={uploadResults}
                                requestParams={requestParams}
                                setRequestParams={setRequestParams}
                                showSubscriptionColumns={!_isEmpty(subscriptions)}
                            />
                        )}
                        <div className="align-self-end mr-3 mt-2 mb-3">
                            {currentStep !== 0 && (
                                <Button
                                    variant="outline-secondary"
                                    className="mr-1"
                                    onClick={() => setCurrentStep((prevState) => prevState - 1)}
                                >
                                    {I18n.t("common.links.previous")}
                                </Button>
                            )}
                            {currentStep !== 2 ? (
                                <Button
                                    variant="primary"
                                    className="text-white ml-1"
                                    disabled={
                                        (currentStep === 1 &&
                                            uploadResults.length === 0 &&
                                            _isEmpty(validationErrors)) ||
                                        (currentStep === 0 && period.from > period.to)
                                    }
                                    onClick={() => setCurrentStep((prevState) => prevState + 1)}
                                >
                                    {I18n.t("common.links.next")}
                                </Button>
                            ) : (
                                <Button
                                    variant="primary"
                                    className="text-white ml-1"
                                    onClick={onSubmit}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting && (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="mr-2"
                                        />
                                    )}

                                    {I18n.t("common.links.submit")}
                                </Button>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default NewTieredDiversitySupplierPage;
