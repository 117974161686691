import * as Yup from "yup";
import moment from "moment";
import _get from "lodash/get";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import I18n from "../../../utils/i18n";

Yup.addMethod(Yup.array, "uniqueProperty", function (propertyPath, message) {
    return this.test("unique", "", function (list) {
        const errors = [];

        list.forEach((item, index) => {
            const propertyValue = _get(item, propertyPath);

            if (propertyValue && _filter(list, [propertyPath, propertyValue]).length > 1) {
                errors.push(
                    this.createError({
                        path: `${this.path}[${index}].${propertyPath}`,
                        message,
                    }),
                );
            }
        });

        if (!_isEmpty(errors)) {
            throw new Yup.ValidationError(errors);
        }

        return true;
    });
});

export default Yup.object().shape({
    organization: Yup.object().shape({
        name: Yup.string().required().max(255).nullable(),
        description: Yup.string().max(3000).nullable(),
        website_url: Yup.string().max(255).nullable(),
        email_from: Yup.string().email().max(255).nullable(),
        currency_id: Yup.string().max(3).nullable(),
        company_structure_attributes: Yup.object().shape({
            duns: Yup.string().length(9).matches(/\d{9}/, I18n.t("errors.messages.not_a_number")).nullable(),
            doing_business_as: Yup.string().max(255).nullable(),
            parent_name: Yup.string().max(255).nullable(),
            parent_duns: Yup.string().length(9).matches(/\d{9}/, I18n.t("errors.messages.not_a_number")).nullable(),
            legal_status: Yup.string().nullable(),
            company_type: Yup.string().nullable(),
            year_founded: Yup.number().integer().min(1800).max(moment().year()).nullable(),
            sales_indicators_attributes: Yup.array().of(
                Yup.object().shape({
                    value: Yup.number().required(),
                    period: Yup.number().integer().min(1992).max(moment().year()).required(),
                }),
            ),
            employees_indicators_attributes: Yup.array().of(
                Yup.object().shape({
                    value: Yup.number().required(),
                    period: Yup.number().integer().min(1992).max(moment().year()).required(),
                }),
            ),
        }),
        ownership_attributes: Yup.object().shape({
            minority_owned: Yup.bool(),
            ethnicity: Yup.string()
                .nullable()
                .when("minority_owned", {
                    is: true,
                    then: Yup.string()
                        .typeError(I18n.t("errors.messages.cant_be_blank"))
                        .required(I18n.t("errors.messages.cant_be_blank")),
                }),
        }),
        industry_relations_attributes: Yup.array()
            .of(
                Yup.object().shape({
                    industry_id: Yup.string(),
                }),
            )
            .uniqueProperty("industry_id", I18n.t("errors.messages.taken")),
        organization_permissions_attributes: Yup.array().of(
            Yup.object().shape({
                permission_id: Yup.string().required(),
            }),
        ),
    }),
});
